*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-2 {
  top: .5rem;
}

.top-7 {
  top: 1.75rem;
}

.top-\[-2\] {
  top: -2px;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.float-right {
  float: right;
}

.m-8 {
  margin: 2rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-mt-page-mobile {
  margin-top: -56px;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[70px\] {
  margin-bottom: 70px;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-24 {
  margin-left: 6rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-\[-10\] {
  margin-left: -10px;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-32 {
  margin-right: 8rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-0\.5 {
  height: .125rem;
}

.h-10 {
  height: 2.5rem;
}

.h-24 {
  height: 6rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-96 {
  height: 24rem;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-nav-mobile {
  height: 56px;
}

.h-screen {
  height: 100vh;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-input {
  min-height: 40px;
}

.min-h-quiz-multi-select {
  min-height: 41px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-16 {
  width: 4rem;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-2\/5 {
  width: 40%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-20 {
  width: 5rem;
}

.w-3\/12 {
  width: 25%;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-\[value\%\] {
  width: value% ;
}

.w-full {
  width: 100%;
}

.min-w-32 {
  min-width: 8rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-md {
  max-width: 28rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.list-none {
  list-style-type: none;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-6 {
  gap: 1.5rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.text-nowrap {
  text-wrap: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-none {
  border-style: none;
}

.border-artichoke-60 {
  --tw-border-opacity: 1;
  border-color: rgb(170 169 155 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-charcoal-100 {
  --tw-border-opacity: 1;
  border-color: rgb(43 41 41 / var(--tw-border-opacity));
}

.border-charcoal-20 {
  --tw-border-opacity: 1;
  border-color: rgb(213 212 212 / var(--tw-border-opacity));
}

.border-charcoal-40 {
  --tw-border-opacity: 1;
  border-color: rgb(170 169 169 / var(--tw-border-opacity));
}

.border-charcoal-60 {
  --tw-border-opacity: 1;
  border-color: rgb(128 126 126 / var(--tw-border-opacity));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}

.border-silver-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 217 209 / var(--tw-border-opacity));
}

.border-silver-60 {
  --tw-border-opacity: 1;
  border-color: rgb(236 232 228 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-x-charcoal-60 {
  --tw-border-opacity: 1;
  border-left-color: rgb(128 126 126 / var(--tw-border-opacity));
  border-right-color: rgb(128 126 126 / var(--tw-border-opacity));
}

.border-b-charcoal-60 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(128 126 126 / var(--tw-border-opacity));
}

.border-b-white {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-artichoke-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(115 112 87 / var(--tw-bg-opacity));
}

.bg-artichoke-40 {
  --tw-bg-opacity: 1;
  background-color: rgb(199 197 189 / var(--tw-bg-opacity));
}

.bg-artichoke-60 {
  --tw-bg-opacity: 1;
  background-color: rgb(170 169 155 / var(--tw-bg-opacity));
}

.bg-charcoal-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(43 41 41 / var(--tw-bg-opacity));
}

.bg-charcoal-20 {
  --tw-bg-opacity: 1;
  background-color: rgb(213 212 212 / var(--tw-bg-opacity));
}

.bg-lemon-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 117 / var(--tw-bg-opacity));
}

.bg-lemon-60 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 181 / var(--tw-bg-opacity));
}

.bg-silver-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 217 209 / var(--tw-bg-opacity));
}

.bg-silver-20 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 246 / var(--tw-bg-opacity));
}

.bg-silver-40 {
  --tw-bg-opacity: 1;
  background-color: rgb(242 240 237 / var(--tw-bg-opacity));
}

.bg-silver-60 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 232 228 / var(--tw-bg-opacity));
}

.bg-silver-80 {
  --tw-bg-opacity: 1;
  background-color: rgb(230 224 219 / var(--tw-bg-opacity));
}

.bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-70 {
  --tw-bg-opacity: .7;
}

.bg-blob-outline {
  background-image: url("blob-bg-outline.e8cea5e2.png");
}

.bg-blob-small {
  background-image: url("blob-bg-small.7057c668.png");
}

.bg-next-steps-standard {
  background-image: url("next-steps-bg.d9b97bd1.png");
}

.bg-wave-result-mobile {
  background-image: url("wave-result-bg-mobile.d598d732.png");
}

.bg-\[length\:130\%\] {
  background-size: 130%;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-clip-border {
  background-clip: border-box;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-top {
  background-position: top;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-\[2px\] {
  stroke-width: 2px;
}

.stroke-\[3px\] {
  stroke-width: 3px;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.font-abc-diatype {
  font-family: ABCDiatype, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-artichoke-100 {
  --tw-text-opacity: 1;
  color: rgb(115 112 87 / var(--tw-text-opacity));
}

.text-artichoke-60 {
  --tw-text-opacity: 1;
  color: rgb(170 169 155 / var(--tw-text-opacity));
}

.text-charcoal-100 {
  --tw-text-opacity: 1;
  color: rgb(43 41 41 / var(--tw-text-opacity));
}

.text-charcoal-40 {
  --tw-text-opacity: 1;
  color: rgb(170 169 169 / var(--tw-text-opacity));
}

.text-charcoal-60 {
  --tw-text-opacity: 1;
  color: rgb(128 126 126 / var(--tw-text-opacity));
}

.text-charcoal-80 {
  --tw-text-opacity: 1;
  color: rgb(86 84 84 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-lemon-100 {
  --tw-text-opacity: 1;
  color: rgb(247 247 117 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-silver-60 {
  --tw-text-opacity: 1;
  color: rgb(236 232 228 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.placeholder-charcoal-40::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(170 169 169 / var(--tw-placeholder-opacity));
}

.placeholder-red-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(254 202 202 / var(--tw-placeholder-opacity));
}

.opacity-10 {
  opacity: .1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-80 {
  opacity: .8;
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@font-face {
  font-family: ABCDiatype;
  font-weight: 400;
  font-style: italic;
  src: url("ABCDiatype-RegularItalic.44271200.woff") format("woff"), url("ABCDiatype-RegularItalic.44271200.woff") format("woff2"), url("ABCDiatype-RegularItalic.4de9eb71.otf") format("opentype");
}

@font-face {
  font-family: ABCDiatype;
  font-weight: 400;
  src: url("ABCDiatype-Regular.7398bef7.woff") format("woff"), url("ABCDiatype-Regular.b092f219.woff2") format("woff2"), url("ABCDiatype-Regular.ed8161bd.otf") format("opentype");
}

@font-face {
  font-family: ABCDiatype;
  font-weight: 600;
  src: url("ABCDiatype-Bold.8b975d92.woff") format("woff"), url("ABCDiatype-Bold.8aac0266.woff2") format("woff2"), url("ABCDiatype-Bold.4f2b64ad.otf") format("opentype");
}

html {
  background-color: #f2f0ed;
  font-size: 15px;
}

@media (width >= 768px) {
  html {
    font-size: 16px;
  }
}

.first\:ml-0:first-child {
  margin-left: 0;
}

.first\:border-t-0:first-child {
  border-top-width: 0;
}

.first\:border-none:first-child {
  border-style: none;
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.last\:rounded-b-lg:last-child {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.last\:border-none:last-child {
  border-style: none;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:bg-silver-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 217 209 / var(--tw-bg-opacity));
}

.focus\:border-charcoal-100:focus {
  --tw-border-opacity: 1;
  border-color: rgb(43 41 41 / var(--tw-border-opacity));
}

.focus\:border-red-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group:hover .group-hover\:text-charcoal-100 {
  --tw-text-opacity: 1;
  color: rgb(43 41 41 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (width >= 640px) {
  .sm\:relative {
    position: relative;
  }

  .sm\:bottom-auto {
    bottom: auto;
  }

  .sm\:left-auto {
    left: auto;
  }

  .sm\:m-0 {
    margin: 0;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mb-\[60px\] {
    margin-bottom: 60px;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:mt-12 {
    margin-top: 3rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-64 {
    height: 16rem;
  }

  .sm\:w-1\/4 {
    width: 25%;
  }

  .sm\:w-2\/6 {
    width: 33.3333%;
  }

  .sm\:w-4\/6 {
    width: 66.6667%;
  }

  .sm\:w-80 {
    width: 20rem;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:rounded-none {
    border-radius: 0;
  }

  .sm\:rounded-xl {
    border-radius: .75rem;
  }

  .sm\:border-t-0 {
    border-top-width: 0;
  }

  .sm\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .sm\:bg-wave-result-standard {
    background-image: url("wave-result-bg-standard.36e569b7.png");
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .sm\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .sm\:pb-16 {
    padding-bottom: 4rem;
  }

  .sm\:pt-16 {
    padding-top: 4rem;
  }

  .sm\:hover\:border:hover {
    border-width: 1px;
  }

  .sm\:hover\:border-charcoal-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(43 41 41 / var(--tw-border-opacity));
  }

  .sm\:hover\:bg-charcoal-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(43 41 41 / var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .sm\:hover\:text-charcoal-100:hover {
    --tw-text-opacity: 1;
    color: rgb(43 41 41 / var(--tw-text-opacity));
  }

  .sm\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

@media (width >= 768px) {
  .md\:left-auto {
    left: auto;
  }

  .md\:-mt-page-desktop {
    margin-top: -65px;
  }

  .md\:mb-2 {
    margin-bottom: .5rem;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:ml-6 {
    margin-left: 1.5rem;
  }

  .md\:mr-6 {
    margin-right: 1.5rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-1\/2 {
    height: 50%;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:h-nav-desktop {
    height: 65px;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-1\/5 {
    width: 20%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:rounded-xl {
    border-radius: .75rem;
  }

  .md\:bg-\[length\:auto\] {
    background-size: auto;
  }

  .md\:p-12 {
    padding: 3rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:pt-36 {
    padding-top: 9rem;
  }

  .md\:pt-40 {
    padding-top: 10rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (width >= 1024px) {
  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:h-96 {
    height: 24rem;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .lg\:text-center {
    text-align: center;
  }
}

@media (width >= 1536px) {
  .\32 xl\:bg-wave-result-wide {
    background-image: url("wave-result-bg-wide.12ad49b5.png");
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}
/*# sourceMappingURL=index.aab61511.css.map */
